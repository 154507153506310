import React from "react";
import PortableText from "../portableText";
import {
  buildImageObj,
  pushShortWordsLineBelow,
  pushShortWordsLineBelowInATitle,
} from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import styles from "./content-page.module.css";

const ContentPage = (props) => {
  const contents = [];
  props._rawContent && contents.push(props._rawContent);
  props._rawContent2 && contents.push(props._rawContent2);
  props._rawContent3 && contents.push(props._rawContent3);
  props._rawContent4 && contents.push(props._rawContent4);
  props._rawContent5 && contents.push(props._rawContent5);

  contents.forEach((content) => {
    pushShortWordsLineBelow(content);
  });

  const formattedTitle = pushShortWordsLineBelowInATitle(props.contentTitle);

  return (
    <>
      {props.category ? (
        <div className={styles.page_header}>
          {props.mainImage && props.mainImage.asset && (
            <div className={styles.main_image}>
              <img
                src={imageUrlFor(buildImageObj(props.mainImage))
                  .height(100)
                  .fit("crop")
                  .auto("format")
                  .url()}
                alt={props.mainImage.alt}
              />
            </div>
          )}
          <h1 className={styles.content_title}>{formattedTitle}</h1>
        </div>
      ) : (
        <h1 className={styles.content_title}>{formattedTitle}</h1>
      )}
      {contents.map((content, index) => (
        <div key={index} className={styles.raw_content}>
          <PortableText blocks={content} />
        </div>
      ))}
    </>
  );
};

export default ContentPage;
